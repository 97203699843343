import { createPopperLite as createPopper } from '@popperjs/core'
import { arrow, offset, preventOverflow } from '@popperjs/core/lib/modifiers'

import { debounce } from 'lib/debounce.js'
import { getAttribute, hasAttribute, qs, removeAttribute, setAttribute } from 'lib/dom.js'
import { dispatch, on } from 'lib/events.js'

const popperModifiers = [
	preventOverflow,
	{
		...offset,
		options: {
			offset: [0, 5]
		}
	},
	{
		...arrow,
		options: {
			element: '.tooltip-arrow'
		}
	}
]

const popperOptions = {
	placement: 'top',
	modifiers: popperModifiers
}

function showTooltip({ target }) {
	setAttribute(qs(`#${ getAttribute(target, 'aria-describedby') }`), 'aria-hidden', 'false')

	// Enable the event listeners
	target.popperInstance.setOptions({
		modifiers: [
			...popperModifiers,
			{
				name: 'eventListeners',
				enabled: true
			}
		],
	})

	// Update its position
	target.popperInstance.update()
}

function hideTooltip({ target }) {
	setAttribute(qs(`#${ getAttribute(target, 'aria-describedby') }`), 'aria-hidden', 'true')

	// Disable the event listeners
	target.popperInstance.setOptions({
		modifiers: [
			...popperModifiers,
			{
				name: 'eventListeners',
				enabled: false
			}
		],
	})
}

function attachTooltip(target) {
	if (hasAttribute(target, 'aria-describedby') && target.popperInstance) {
		return
	}

	if (!hasAttribute(target, 'id')) {
		setAttribute(target, 'id', `tooltipTrigger-${ Math.floor(Math.random() * 100000) }`)
	}

	const tooltip = document.createElement('div')

	tooltip.classList.add('tooltip')

	const contents = document.createElement('div')
	contents.classList.add('tooltip-contents')
	contents.textContent = getAttribute(target, 'title')

	tooltip.appendChild(contents)

	setAttribute(tooltip, 'aria-hidden', 'true')
	setAttribute(tooltip, 'role', 'tooltip')
	setAttribute(tooltip, 'id', `tooltip-${ Math.floor(Math.random() * 100000) }`)

	removeAttribute(target, 'title')
	setAttribute(target, 'aria-describedby', getAttribute(tooltip, 'id'))

	const arrowElement = document.createElement('div')
	arrowElement.classList.add('tooltip-arrow')

	tooltip.appendChild(arrowElement)
	document.body.appendChild(tooltip)

	target.popperInstance = createPopper(target, tooltip, popperOptions)

	on(target, 'mouseenter', showTooltip, { passive: true })
	on(target, 'mouseleave', hideTooltip, { passive: true })

	dispatch(target, 'mouseenter')
}

/*
 * Debounced to avoid hitting this constantly.
 */
on(document, 'mouseover', debounce(({ target }) => {
	if (hasAttribute(target, 'title') && getAttribute(target, 'aria-describedby') === null && (getAttribute(target, 'title') || '').trim() !== '') {
		attachTooltip(target)
	}
}, 100), { passive: true })
